import React, { useState } from 'react';
import {
  Box,
  Typography,
  Container,
  CssBaseline,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button
} from '@mui/material';
import Logout from './Authentication/Logout';

const Dashboard = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleLogoutClick = () => {
    setOpenDialog(true);
  };

  return (
    <>
      <CssBaseline />
      <Container maxWidth="lg">
        <Typography component="h3" variant="h4" m={2} p={2} align="center" gutterBottom>
          Admin Dashboard
        </Typography>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" onClick={handleLogoutClick}>
            Logout
          </Button>
        </Box>
        <Logout openDialog={openDialog} setOpenDialog={setOpenDialog} />
        <Box sx={{ m: 2 }} />
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image="https://images.unsplash.com/profile-1441298803695-accd94000cac?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=128&w=128&s=32085a077889586df88bfbe406692202"
                  alt="random image"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Total Users
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    1000
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image="https://images.unsplash.com/profile-1441298803695-accd94000cac?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=128&w=128&s=32085a077889586df88bfbe406692202"
                  alt="random image"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Total Posts
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    1000
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image="https://images.unsplash.com/profile-1441298803695-accd94000cac?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=128&w=128&s=32085a077889586df88bfbe406692202"
                  alt="random image"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Total Comments
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    1000
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default Dashboard;