import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  CssBaseline,
  Alert,
  Paper,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [role, setRole] = useState('');

  const staticEmail = 'admin@gmail.com';
  const staticPassword = 'admin@gmail.com';

  const userEmail = 'user@gmail.com';
  const userPassword = 'user@gmail.com';

  const requiredMessage = 'Please fill in all the fields';
  const successMessage = 'Login Successfully';
  const failureMessage = 'Invalid Credentials';

  const adminRedirectURL = '/dashboard';
  const userRedirectURL = '/user-dashboard';

  const handleSubmit = (event) => {
    event.preventDefault();

    if (email.trim() === '' || password.trim() === '') {
      setError(<Alert severity="error">{requiredMessage}</Alert>);
      setTimeout(() => {
        setError('');
      }, 1000);
      return;
    }

    // Check for Admin credentials
    if (email === staticEmail && password === staticPassword) {
      setRole('Admin');
      localStorage.setItem('UserData', JSON.stringify({ email, role: 'Admin' }));
      setError(<Alert severity="success">{successMessage}</Alert>);
      setTimeout(() => {
        window.location.href = adminRedirectURL;
      }, 1000);
    }
    // Check for User credentials
    else if (email === userEmail && password === userPassword) {
      setRole('User');
      localStorage.setItem('UserData', JSON.stringify({ email, role: 'User' }));
      setError(<Alert severity="success">{successMessage}</Alert>);
      setTimeout(() => {
        window.location.href = userRedirectURL;
      }, 1000);
    }
    // Invalid Credentials
    else {
      setError(<Alert severity="error">{failureMessage}</Alert>);
      setTimeout(() => {
        setEmail('');
        setPassword('');
        setError('');
      }, 1000);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: '100vh',
            justifyContent: 'center',
            padding: 2,
          }}
        >
          <Paper
            elevation={10}
            sx={{
              padding: 4,
              borderRadius: 3,
              boxShadow: '0px 10px 30px rgba(0,0,0,0.1)',
              width: '100%',
              maxWidth: '400px',
              backgroundColor: '#fff',
            }}
          >
            <Typography component="h1" variant="h5" align="center" gutterBottom>
              Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  borderRadius: '10px',
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  borderRadius: '10px',
                }}
              />
              {error && (
                <Box mt={2}>
                  {error}
                </Box>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  padding: '10px',
                  borderRadius: '20px',
                  backgroundColor: '#667eea',
                  '&:hover': {
                    backgroundColor: '#764ba2',
                  },
                  boxShadow: '0px 5px 15px rgba(0,0,0,0.2)',
                }}
              >
                Login
              </Button>
            </Box>
          </Paper>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Login;