import React, { useState } from 'react';
import {
    Box,
    Typography,
    Container,
    CssBaseline,
    Button
} from '@mui/material';
import Logout from './Authentication/Logout';

const UserDashboard = () => {
    const [openDialog, setOpenDialog] = useState(false);

    const handleLogoutClick = () => {
        setOpenDialog(true);
    };

    return (
        <>
            <CssBaseline />
            <Container maxWidth="lg">
                <Typography component="h3" variant="h4" m={2} p={2} align="center" gutterBottom>
                    User Dashboard
                </Typography>
                <Box display="flex" justifyContent="flex-end">
                    <Button variant="contained" onClick={handleLogoutClick}>
                        Logout
                    </Button>
                </Box>
                <Logout openDialog={openDialog} setOpenDialog={setOpenDialog} />
                <Box sx={{ m: 2 }} />
            </Container>
        </>
    );
};

export default UserDashboard;