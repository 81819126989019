import React from 'react';
import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';
import Login from './Components/Auth/Login';
import Dashboard from './Components/Dashboard/Dashboard';
import UserDashboard from './Components/Dashboard/UserDashboard';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={localStorage.getItem('UserData') ? <Dashboard /> : <Navigate to="/" />} />
        <Route path="/user-dashboard" element={localStorage.getItem('UserData') ? <UserDashboard /> : <Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;